import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrophy, faCheckCircle, faHome } from '@fortawesome/free-solid-svg-icons';

const TriviaSummaryScreen = () => {
  console.log("In TriviaSummaryScreen");
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const {
    categoryName,
    quizName,
    correctCount,
    incorrectCount,
    completionPercentage,
    totalPoints,
    totalBonusPoints,
    totalScore
  } = location.state;

  const passedDailyQuiz = categoryName === 'dailyTrivia' && correctCount >= 3;
  const isSupportedCategory = [
    'dailyTrivia',
    'randomQuizAnyCategory10Timed',
    'triviaArt',
    'triviaEntertainment',
    'triviaGeneralKnowledge',
    'triviaGeography',
    'triviaHistory',
    'triviaLiterature',
    'triviaScience',
    'triviaSports'
  ].includes(categoryName);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLoginRedirect = () => {
    if (window.confirm("You need to log in to save your scores and see leaderboards. Do you want to log in now?")) {
      navigate('/login');  // Adjust to your login route
    }
  };

  const handleGoBack = () => {
    navigate('/landing');  // Navigate to main page (adjust if necessary)
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={handleGoBack} />
        </div>
        <h1 className="text-xl font-bold">Trivia Summary</h1>
        <div></div>
      </nav>

      <div className="w-full max-w-3xl mt-8 mb-8 bg-white p-6 rounded-lg shadow-lg text-center">
        <FontAwesomeIcon icon={faTrophy} size="3x" color="orange" />
        <p className="text-xl font-bold mt-4">Quiz Name: {quizName}</p>
        <p className="text-lg">Correct Answers: {correctCount}</p>
        <p className="text-lg">Incorrect Answers: {incorrectCount}</p>
        <p className="text-lg">Completion Percentage: {completionPercentage}%</p>
        <p className="text-lg">Total Points: {totalPoints}</p>
        <p className="text-lg">Bonus Points: {totalBonusPoints}</p>
        <p className="text-lg mb-4">Total Score: {totalScore}</p>

        {passedDailyQuiz && (
          <div className="flex items-center justify-center mb-4">
            <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
            <p className="text-lg text-green-600 ml-2">You passed today's daily quiz!</p>
          </div>
        )}

        {isSupportedCategory && (
          <button
            className="bg-purple-600 text-white px-4 py-2 rounded-lg mt-4 flex items-center justify-center gap-2 hover:bg-purple-700 transition-all"
            onClick={() => navigate('/landing', { state: { categoryName } })}
          >
            <FontAwesomeIcon icon={faTrophy} />
            View Leaderboard
          </button>
        )}

        {!user && (
          <div className="mt-6">
            <p>Your scores can be saved if you log in.</p>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg mt-2 hover:bg-blue-700 transition-all"
              onClick={handleLoginRedirect}
            >
              Log In
            </button>
          </div>
        )}

        <button
          className="bg-purple-600 text-white px-4 py-2 rounded-lg mt-6 hover:bg-purple-700 transition-all"
          onClick={handleGoBack}
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default TriviaSummaryScreen;