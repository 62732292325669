import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHome } from '@fortawesome/free-solid-svg-icons';

function PlayQuiz() {
  const [quizData, setQuizData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [sessionCorrectCount, setSessionCorrectCount] = useState(0);
  const [sessionIncorrectCount, setSessionIncorrectCount] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [timer, setTimer] = useState(25); // 25 seconds timer
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadQuizData = async () => {
      const { quizFile, quizType, quizName } = location.state;

      try {
        console.log("loadQuizData: " + quizFile);
        const response = await fetch(`https://storage.googleapis.com/quiznect-quiz-storage-bucket/${quizFile}`);
        const data = await response.json();
        console.log("response: " + data);
        
        if (quizType === 'randomQuiz10Timed' && data.questions) {
          const questions = data.questions.sort(() => Math.random() - 0.5).slice(0, 10);
          setQuizData({ questions, quizName });
        } else {
          setQuizData({ questions: data.questions, quizName });
        }
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };

    loadQuizData();
  }, [location.state]);

  useEffect(() => {
    if (quizData) {
      const question = quizData.questions[currentQuestionIndex];
      setShuffledOptions(shuffleArray([...question.options]));
      setCorrectAnswer(question.correctAnswer);
    }
  }, [quizData, currentQuestionIndex]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleAnswerSelection = (answer) => {
    if (selectedAnswer || timer === 0) return;

    setSelectedAnswer(answer);
    const question = quizData.questions[currentQuestionIndex];
    const answerIsCorrect = answer === question.correctAnswer;
    setIsCorrect(answerIsCorrect);

    if (answerIsCorrect) {
      setSessionCorrectCount((prevCount) => prevCount + 1);
    } else {
      setSessionIncorrectCount((prevCount) => prevCount + 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedAnswer(null);
      setIsCorrect(null);
      setTimer(25); // Reset the timer for the next question
    } else {
      handleExitQuiz();
    }
  };

  const handleExitQuiz = () => {
    const totalPoints = sessionCorrectCount * 10;
    const totalScore = totalPoints; // You can add bonus points logic if needed

    navigate('/trivia-summary', {
      state: {
        correctCount: sessionCorrectCount,
        incorrectCount: sessionIncorrectCount,
        completionPercentage,
        totalScore,
      },
    });
  };

  const handleGoHome = () => {
    navigate('/landing');
  };

  if (!quizData || quizData.questions.length === 0) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  const handleFlagQuestion = () => {
    navigate('/q-feedback', {
      state: {
        quizFile: location.state.quizFile,
        questionIndex: currentQuestionIndex,
        questionText: quizData.questions[currentQuestionIndex].question,
        quizState: {
          currentQuestionIndex,
          selectedAnswer,
          sessionCorrectCount,
          sessionIncorrectCount,
          completionPercentage,
          shuffledOptions,
          quizData,
          timer,
        },
      },
    });
  };
  const question = quizData.questions[currentQuestionIndex];
  const explanationHtml = selectedAnswer ? question.explanation : '';

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={handleGoHome} />
        </div>
        <h1 className="text-xl font-bold">{quizData.quizName.replace(/_/g, ' ')} - Question {currentQuestionIndex + 1} of {quizData.questions.length}</h1>
        <div></div>
      </nav>

      {selectedAnswer && (
        <button className="absolute top-4 right-4 bg-[#9C27B0] text-white p-2 rounded-lg cursor-pointer" onClick={handleFlagQuestion}>
          <FontAwesomeIcon icon={faFlag} />
        </button>
      )}

      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg mt-8">
        <div className="text-lg font-semibold text-center mb-4" dangerouslySetInnerHTML={{ __html: question.question.replace(/\n/g, '<br/>') }} />

        <div className="grid gap-4">
          {shuffledOptions.map((option, index) => (
            <div
              key={index}
              className={`bg-[#9C27B0] text-white p-4 rounded-lg text-center cursor-pointer ${
                selectedAnswer === option ? (isCorrect ? 'bg-green-600' : 'bg-red-600') : ''
              }`}
              onClick={() => handleAnswerSelection(option)}
            >
              <div dangerouslySetInnerHTML={{ __html: option.replace(/\n/g, '<br/>') }} />
            </div>
          ))}
        </div>

        {selectedAnswer && (
          <>
            {explanationHtml && explanationHtml.length > 0 && (
              <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-6">
                <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
              </div>
            )}
            <div className="flex justify-center mt-6">
              {currentQuestionIndex < quizData.questions.length - 1 ? (
                <button className="bg-[#9C27B0] text-white p-4 rounded-lg" onClick={handleNextQuestion}>Next</button>
              ) : (
                <button className="bg-[#9C27B0] text-white p-4 rounded-lg" onClick={handleExitQuiz}>Finish Quiz</button>
              )}
            </div>
            {question.url && (
              <div className="text-center mt-4">
                <a href={question.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                  {question.url.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PlayQuiz;