// DailyQuizBanner.js
import React from 'react';
import './DailyQuizBanner.css';  // Use same style as the DailyQuizBanner.css

const RandomQuizBanner = ({ bannerText, quiz, quizCompleted, onQuizStart }) => {
  if (!quiz) return null;

  return (
    <div className="daily-quiz-banner">
      <h2>{bannerText}</h2>
      
      <button
        className={`start-quiz-button ${quizCompleted ? 'completed' : ''}`}
        onClick={onQuizStart}
        disabled={quizCompleted}
      >
        {quizCompleted ? 'Quiz Completed' : 'Start Random Quiz'}
      </button>
      
    </div>
  );
};

export default RandomQuizBanner;