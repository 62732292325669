import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './HomePage';
import LandingPage from './LandingPage';
import LearningFocusPage from './LearningFocusPage';
import TriviaScreen from './TriviaScreen';
import TriviaSummaryScreen from './TriviaSummaryScreen';
import QFeedbackPage from './QFeedbackPage';
import QuizzesListLocal from './QuizzesListLocal';
import TriviaListLocal from './TriviaListLocal';
import ProfileSettingsPage from './ProfileSettingsPage';
import CreateScreenName from './CreateScreenName';
import QuizGenerationProgressPage from './QuizGenerationProgressPage';
import QuizPage from './QuizPage';
import PlayQuiz from './PlayQuiz';
import QuizzesViewer from './QuizzesViewer';
import MyQuizzesViewer from './MyQuizzesViewer';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/learning-focus" element={<LearningFocusPage />} />
        <Route path="/trivia-screen" element={<TriviaScreen />} />
        <Route path="/trivia-summary" element={<TriviaSummaryScreen />} />
        <Route path="/q-feedback" element={<QFeedbackPage />} />
        <Route path="/quizzes-free" element={<QuizzesListLocal />} />
        <Route path="/trivia" element={<TriviaListLocal />} />
        <Route path="/profile" element={<ProfileSettingsPage />} />
        <Route path="/create-screen-name" element={<CreateScreenName />} />
        <Route path="/quiz-generation-progress" element={<QuizGenerationProgressPage />} />
        <Route path="/quizpage" element={<QuizPage />} />
        <Route path="/play-quiz" element={<PlayQuiz />} />
        <Route path="/quizzes-viewer" element={<QuizzesViewer />} />
        <Route path="/my-quizzes-viewer" element={<MyQuizzesViewer />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;