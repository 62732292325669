import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFilm, faGlobe, faFlask, faBook, faTrophy, faBrain, faPalette, faGraduationCap, 
  faCode, faCalculator, faSchool, faLanguage, faGavel, faHospital, faCogs, faCity, 
  faUtensils, faBusinessTime, faDumbbell, faBookReader, faFlag, faLaptopCode 
} from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import './QuizzesListLocal.css';

const QuizzesSection = ({ section, onQuizSelect }) => {
  
  // Function to map the category icon names from quizzesData to the FontAwesome icons
  const getIconComponent = (icon) => {
    switch (icon) {
      // Existing trivia category icons
      case 'film-outline':
        return <FontAwesomeIcon icon={faFilm} />;
      case 'globe-outline':
        return <FontAwesomeIcon icon={faGlobe} />;
      case 'flask-outline':
        return <FontAwesomeIcon icon={faFlask} />;
      case 'book-outline':
        return <FontAwesomeIcon icon={faBook} />;
      case 'trophy-outline':
        return <FontAwesomeIcon icon={faTrophy} />;
      case 'brain':
        return <FontAwesomeIcon icon={faBrain} />;
      case 'palette':
        return <FontAwesomeIcon icon={faPalette} />;
        
      // Quiz-specific category icons based on the console output
      case 'code-slash-outline':
        return <FontAwesomeIcon icon={faCode} />;
      case 'calculator-outline':
        return <FontAwesomeIcon icon={faCalculator} />;
      case 'school-outline':
        return <FontAwesomeIcon icon={faSchool} />;
      case 'language':
        return <FontAwesomeIcon icon={faLanguage} />;
      case 'gavel':
        return <FontAwesomeIcon icon={faGavel} />;
      case 'local-hospital':
        return <FontAwesomeIcon icon={faHospital} />;
      case 'engineering':
        return <FontAwesomeIcon icon={faCogs} />;
      case 'architecture':
        return <FontAwesomeIcon icon={faCity} />;
      case 'restaurant':
        return <FontAwesomeIcon icon={faUtensils} />;
      case 'business':
        return <FontAwesomeIcon icon={faBusinessTime} />;
      case 'fitness-center':
        return <FontAwesomeIcon icon={faDumbbell} />;
      case 'menu-book':
        return <FontAwesomeIcon icon={faBookReader} />;
      case 'flag':
        return <FontAwesomeIcon icon={faFlag} />;
      case 'laptop-outline':
        return <FontAwesomeIcon icon={faLaptopCode} />;
        
      // Default icon for unmatched cases
      default:
        return <FontAwesomeIcon icon={faGraduationCap} />;  
    }
  };

  return (
    <div className="section-container">
      <div className="section-header">
        {getIconComponent(section.icon)} {/* Display the correct icon */}
        <h2 className="section-title">{section.name}</h2>
      </div>
      <div className="quiz-list">
        {section.quizzes.map((quiz, quizIndex) => (
          <button
            key={quizIndex}
            className="quiz-button"
            onClick={() => onQuizSelect({
              quizScreen: quiz.quizScreen,
              jsonFile: quiz.jsonFile,
              name: quiz.name,
              sectionName: section.name,
            })}
          >
            {quiz.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default QuizzesSection;


