import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from './firebase';
import './QFeedbackPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faThumbsUp, faThumbsDown, faHeart } from '@fortawesome/free-solid-svg-icons';

function QFeedbackPage() {
  const [reaction, setReaction] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [comment, setComment] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [clarity, setClarity] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { quizFile, questionIndex, questionText, previousScreenState } = location.state;

  const feedbackOptions = [
    { id: 'incorrect', label: 'The answer is incorrect' },
    { id: 'ambiguous', label: 'The answer is ambiguous' },
    { id: 'outdated', label: 'The information is wrong or outdated' },
  ];

  const toggleReason = (id) => {
    if (reasons.includes(id)) {
      setReasons(reasons.filter(reason => reason !== id));
    } else {
      setReasons([...reasons, id]);
    }
  };

  const handleSubmitFeedback = async () => {
    if (!reaction) {
      alert('Please select a reaction.');
      return;
    }

    try {
      const feedbackRef = collection(firestore, 'questionFeedback');
      await addDoc(feedbackRef, {
        quizFile,
        questionIndex,
        questionText,
        reaction,
        reasons,
        comment,
        difficulty,
        clarity,
        timestamp: serverTimestamp(),
      });

      alert('Feedback Submitted');
      navigate(-1, { state: previousScreenState });
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again later.');
    }
  };

  const handleBack = () => {
    navigate(-1, { state: previousScreenState });
  };

  return (
    <div className="feedback-page">
      <div className="feedback-header">
        <button className="back-button" onClick={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h2>Provide Feedback</h2>
      </div>
      
      <p className="question-text">{questionText}</p>

      <div className="section">
        <h4>How was this question?</h4>
        <div className="reactions-container">
          <button
            className={`reaction-button ${reaction === 'thumbs-down' ? 'selected' : ''}`}
            onClick={() => setReaction('thumbs-down')}
          >
            <FontAwesomeIcon icon={faThumbsDown} />
          </button>
          <button
            className={`reaction-button ${reaction === 'thumbs-up' ? 'selected' : ''}`}
            onClick={() => setReaction('thumbs-up')}
          >
            <FontAwesomeIcon icon={faThumbsUp} />
          </button>
          <button
            className={`reaction-button ${reaction === 'heart' ? 'selected' : ''}`}
            onClick={() => setReaction('heart')}
          >
            <FontAwesomeIcon icon={faHeart} />
          </button>
        </div>
      </div>

      <div className="section difficulty-section">
        <h4>Question difficulty:</h4>
        <div className="difficulty-options">
          <button
            className={`difficulty-button ${difficulty === 'Easy' ? 'selected' : ''}`}
            onClick={() => setDifficulty('Easy')}
          >
            Easy
          </button>
          <button
            className={`difficulty-button ${difficulty === 'Medium' ? 'selected' : ''}`}
            onClick={() => setDifficulty('Medium')}
          >
            Medium
          </button>
          <button
            className={`difficulty-button ${difficulty === 'Hard' ? 'selected' : ''}`}
            onClick={() => setDifficulty('Hard')}
          >
            Hard
          </button>
        </div>
      </div>

      <div className="section clarity-section">
        <h4>Question clarity:</h4>
        <div className="clarity-options">
          <button
            className={`clarity-button ${clarity === 'Unclear' ? 'selected' : ''}`}
            onClick={() => setClarity('Unclear')}
          >
            Unclear
          </button>
          <button
            className={`clarity-button ${clarity === 'Clear' ? 'selected' : ''}`}
            onClick={() => setClarity('Clear')}
          >
            Clear
          </button>
          <button
            className={`clarity-button ${clarity === 'Very Clear' ? 'selected' : ''}`}
            onClick={() => setClarity('Very Clear')}
          >
            Very Clear
          </button>
        </div>
      </div>

      <div className="section comment-section">
        <h4>Comments</h4>
        <textarea
          className="comment-input"
          placeholder="Enter your comments here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <div className="section issue-section">
        <h4>Issues with this question?</h4>
        {feedbackOptions.map(option => (
          <label key={option.id} className="checkbox-container">
            <input
              type="checkbox"
              checked={reasons.includes(option.id)}
              onChange={() => toggleReason(option.id)}
            />
            <span className="checkbox-label">{option.label}</span>
          </label>
        ))}
      </div>

      <button className="submit-button" onClick={handleSubmitFeedback}>
        Submit Feedback
      </button>
    </div>
  );
}

export default QFeedbackPage;
