import { useState, useEffect } from 'react';
import { fnv1aHash } from './quizUtils'; // Import the hash function

const useDailyQuiz = (quizzesData) => {
  const [dailyQuiz, setDailyQuiz] = useState(null);
  const [streakCount, setStreakCount] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);

  useEffect(() => {
    const fetchDailyQuiz = () => {
      const today = new Date().toISOString().slice(0, 10); // Get YYYY-MM-DD format
      const hash = fnv1aHash(today);
      const allQuizzes = quizzesData.sections.flatMap(section =>
        section.quizzes.map(quiz => ({ ...quiz, categoryName: section.name }))
      );
      const index = hash % allQuizzes.length;
      const todayQuiz = allQuizzes[index];

      if (todayQuiz) {
        setDailyQuiz(todayQuiz);
      } else {
        console.error("No quiz found for today.");
      }
    };

    fetchDailyQuiz();
    //setStreakCount(5); // Stubbed streak count
    setQuizCompleted(false); // Stubbed quiz completion status
  }, [quizzesData]);

  return { dailyQuiz, streakCount, quizCompleted };
};

export default useDailyQuiz;
