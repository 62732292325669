const triviaFileMapping = {
  'trivia_art_artists.json': require('./../assets/trivia_art_artists.json'),
  'trivia_art_sat.json': require('./../assets/trivia_art_sat.json'),
  'trivia_art_famous_artists.json': require('./../assets/trivia_art_famous_artists.json'),
  'trivia_art_history.json': require('./../assets/trivia_art_history.json'),
  'trivia_art_music_theory.json': require('./../assets/trivia_art_music_theory.json'),
  'trivia_art_iconic_paintings.json': require('./../assets/trivia_art_iconic_paintings.json'),
  'trivia_art_movements.json': require('./../assets/trivia_art_movements.json'),
  'trivia_entertainment_movies.json': require('./../assets/trivia_entertainment_movies.json'),
  'trivia_entertainment_music.json': require('./../assets/trivia_entertainment_music.json'),
  'trivia_entertainment_tvshows.json': require('./../assets/trivia_entertainment_tvshows.json'),
  'trivia_entertainment_pop_culture.json': require('./../assets/trivia_entertainment_pop_culture.json'),
  'trivia_general_cooking.json': require('./../assets/trivia_general_cooking.json'),
  'trivia_general_current_events.json': require('./../assets/trivia_general_current_events.json'),
  'trivia_general_miscellaneous_facts.json': require('./../assets/trivia_general_miscellaneous_facts.json'),
  'trivia_general_transportation.json': require('./../assets/trivia_general_transportation.json'),
  'trivia_general_spanish.json': require('./../assets/trivia_general_spanish.json'),
  'trivia_general_french.json': require('./../assets/trivia_general_french.json'),
  'trivia_general_portuguese.json': require('./../assets/trivia_general_portuguese.json'),
  'trivia_general_math_11thgrade.json': require('./../assets/trivia_general_math_11thgrade.json'),
  'trivia_general_SAT_verbal.json': require('./../assets/trivia_general_SAT_verbal.json'),
  'trivia_general_electrical_engineering.json': require('./../assets/trivia_general_electrical_engineering.json'),

  'trivia_quiz_wilderness_survival.json': require('./../assets/trivia_quiz_wilderness_survival.json'),
  

  'trivia_software_and_it_system_design.json': require('./../assets/trivia_software_and_it_system_design.json'),

  'trivia_quiz_algebra.json': require('./../assets/trivia_quiz_algebra.json'),
  'trivia_quiz_geometry.json': require('./../assets/trivia_quiz_geometry.json'),
  'trivia_quiz_statistics.json': require('./../assets/trivia_quiz_statistics.json'),
  'trivia_quiz_physics.json': require('./../assets/trivia_quiz_physics.json'),
  'trivia_quiz_iOS_Dev.json': require('./../assets/trivia_quiz_iOS_Dev.json'),
  'trivia_quiz_web_dev_coding.json': require('./../assets/trivia_quiz_web_dev_coding.json'),
  'trivia_quiz_dev_ops.json': require('./../assets/trivia_quiz_dev_ops.json'),
  'trivia_quiz_cybersecurity.json': require('./../assets/trivia_quiz_cybersecurity.json'),
  'trivia_quiz_cloud_computing.json': require('./../assets/trivia_quiz_cloud_computing.json'),
  'trivia_quiz_networking.json': require('./../assets/trivia_quiz_networking.json'),
  'trivia_quiz_database_management.json': require('./../assets/trivia_quiz_database_management.json'),
  'trivia_quiz_gcp_firebase.json': require('./../assets/trivia_quiz_gcp_firebase.json'),
  'trivia_quiz_computer_science.json': require('./../assets/trivia_quiz_computer_science.json'),
  'trivia_quiz_python_coding.json': require('./../assets/trivia_quiz_python_coding.json'),
  'trivia_quiz_auto_mechanics.json': require('./../assets/trivia_quiz_auto_mechanics.json'),
  'trivia_quiz_joke_telling.json': require('./../assets/trivia_quiz_joke_telling.json'),
  'trivia_quiz_public_speaking.json': require('./../assets/trivia_quiz_public_speaking.json'),
  'trivia_quiz_parenting_2_year_old.json': require('./../assets/trivia_quiz_parenting_2_year_old.json'),

  
  'trivia_quiz_gre_math.json': require('./../assets/trivia_quiz_gre_math.json'),
  'trivia_quiz_gre_verbal.json': require('./../assets/trivia_quiz_gre_verbal.json'),
  'trivia_quiz_bar_exam_mbe.json': require('./../assets/trivia_quiz_bar_exam_mbe.json'),
  'trivia_quiz_constitutional_law.json': require('./../assets/trivia_quiz_constitutional_law.json'),
  'trivia_quiz_criminal_law.json': require('./../assets/trivia_quiz_criminal_law.json'),
  'trivia_quiz_contract_law.json': require('./../assets/trivia_quiz_contract_law.json'),
  'trivia_quiz_mcat_biology.json': require('./../assets/trivia_quiz_mcat_biology.json'),
  'trivia_quiz_mcat_chemistry.json': require('./../assets/trivia_quiz_mcat_chemistry.json'),
  'trivia_quiz_nursing.json': require('./../assets/trivia_quiz_nursing.json'),
  'trivia_quiz_anatomy_physiology.json': require('./../assets/trivia_quiz_anatomy_physiology.json'),
  'trivia_quiz_mechanical_engineering.json': require('./../assets/trivia_quiz_mechanical_engineering.json'),
  'trivia_quiz_civil_engineering.json': require('./../assets/trivia_quiz_civil_engineering.json'),
  'trivia_quiz_chemical_engineering.json': require('./../assets/trivia_quiz_chemical_engineering.json'),
  'trivia_quiz_architectural_design.json': require('./../assets/trivia_quiz_architectural_design.json'),
  'trivia_quiz_building_materials.json': require('./../assets/trivia_quiz_building_materials.json'),
  'trivia_quiz_sustainable_architecture.json': require('./../assets/trivia_quiz_sustainable_architecture.json'),
  'trivia_quiz_construction_management.json': require('./../assets/trivia_quiz_construction_management.json'),
  'trivia_quiz_cooking_techniques.json': require('./../assets/trivia_quiz_cooking_techniques.json'),
  'trivia_quiz_world_cuisine.json': require('./../assets/trivia_quiz_world_cuisine.json'),
  'trivia_quiz_pastry_baking.json': require('./../assets/trivia_quiz_pastry_baking.json'),
  'trivia_quiz_food_safety.json': require('./../assets/trivia_quiz_food_safety.json'),
  'trivia_quiz_bartending_mixology.json': require('./../assets/trivia_quiz_bartending_mixology.json'),
  'trivia_quiz_investment_banking.json': require('./../assets/trivia_quiz_investment_banking.json'),
  'trivia_quiz_accounting.json': require('./../assets/trivia_quiz_accounting.json'),
  'trivia_quiz_financial_analysis.json': require('./../assets/trivia_quiz_financial_analysis.json'),
  'trivia_quiz_marketing.json': require('./../assets/trivia_quiz_marketing.json'),
  'trivia_quiz_project_management.json': require('./../assets/trivia_quiz_project_management.json'),
  'trivia_quiz_human_resources.json': require('./../assets/trivia_quiz_human_resources.json'),
  'trivia_quiz_nutrition.json': require('./../assets/trivia_quiz_nutrition.json'),
  'trivia_quiz_fitness_training.json': require('./../assets/trivia_quiz_fitness_training.json'),
  'trivia_quiz_mental_health.json': require('./../assets/trivia_quiz_mental_health.json'),
  'trivia_quiz_public_health.json': require('./../assets/trivia_quiz_public_health.json'),
  'trivia_quiz_teaching_certification.json': require('./../assets/trivia_quiz_teaching_certification.json'),
  'trivia_quiz_curriculum_development.json': require('./../assets/trivia_quiz_curriculum_development.json'),
  'trivia_quiz_educational_psychology.json': require('./../assets/trivia_quiz_educational_psychology.json'),
  'trivia_quiz_special_education.json': require('./../assets/trivia_quiz_special_education.json'),
  'trivia_quiz_philosophy.json': require('./../assets/trivia_quiz_philosophy.json'),
  'trivia_quiz_history_of_music.json': require('./../assets/trivia_quiz_history_of_music.json'),
  'trivia_quiz_film_studies.json': require('./../assets/trivia_quiz_film_studies.json'),
  'trivia_quiz_theater_drama.json': require('./../assets/trivia_quiz_theater_drama.json'),
  'trivia_quiz_photography_studies.json': require('./../assets/trivia_quiz_photography_studies.json'),
  'trivia_quiz_time_management.json': require('./../assets/trivia_quiz_time_management.json'),
  'trivia_quiz_leadership.json': require('./../assets/trivia_quiz_leadership.json'),
  'trivia_quiz_communication_skills.json': require('./../assets/trivia_quiz_communication_skills.json'),
  'trivia_quiz_entrepreneurship.json': require('./../assets/trivia_quiz_entrepreneurship.json'),
  'trivia_quiz_high_school_english.json': require('./../assets/trivia_quiz_high_school_english.json'),
  'trivia_quiz_world_history.json': require('./../assets/trivia_quiz_world_history.json'),
  'trivia_quiz_us_history.json': require('./../assets/trivia_quiz_us_history.json'),
  'trivia_quiz_psychology.json': require('./../assets/trivia_quiz_psychology.json'),
  'trivia_quiz_sociology.json': require('./../assets/trivia_quiz_sociology.json'),
  'trivia_quiz_microeconomics.json': require('./../assets/trivia_quiz_microeconomics.json'),
  'trivia_quiz_macroeconomics.json': require('./../assets/trivia_quiz_macroeconomics.json'),
  'trivia_quiz_us_citizenship_test.json': require('./../assets/trivia_quiz_us_citizenship_test.json'),
  'trivia_quiz_aws_system_architecture.json': require('./../assets/trivia_quiz_aws_system_architecture.json'),
  'trivia_quiz_farming.json': require('./../assets/trivia_quiz_farming.json'),
  

  
  'trivia_geography_countries_and_capitals.json': require('./../assets/trivia_geography_countries_and_capitals.json'),
  'trivia_geography_landmarks.json': require('./../assets/trivia_geography_landmarks.json'),
  'trivia_geography_natural_wonders.json': require('./../assets/trivia_geography_natural_wonders.json'),
  'trivia_geography_world_maps.json': require('./../assets/trivia_geography_world_maps.json'),
  'trivia_history_ancient_civilizations.json': require('./../assets/trivia_history_ancient_civilizations.json'),
  'trivia_history_famous_figures.json': require('./../assets/trivia_history_famous_figures.json'),
  'trivia_history_historical_events.json': require('./../assets/trivia_history_historical_events.json'),
  'trivia_history_social_studies_sat.json': require('./../assets/trivia_history_social_studies_sat.json'),
  'trivia_history_wars_and_conflicts.json': require('./../assets/trivia_history_wars_and_conflicts.json'),
  'trivia_literature_authors.json': require('./../assets/trivia_literature_authors.json'),
  'trivia_literature_sat.json': require('./../assets/trivia_literature_sat.json'),
  'trivia_literature_classic_books.json': require('./../assets/trivia_literature_classic_books.json'),
  'trivia_literature_famous_quotes.json': require('./../assets/trivia_literature_famous_quotes.json'),
  'trivia_literature_literary_terms.json': require('./../assets/trivia_literature_literary_terms.json'),
  'trivia_science_biology.json': require('./../assets/trivia_science_biology.json'),
  'trivia_science_biology_sat.json': require('./../assets/trivia_science_biology_sat.json'),
  'trivia_science_chemistry.json': require('./../assets/trivia_science_chemistry.json'),
  'trivia_science_chemistry_sat.json': require('./../assets/trivia_science_chemistry_sat.json'),
  'trivia_science_physics.json': require('./../assets/trivia_science_physics.json'),
  'trivia_science_physicsSAT.json': require('./../assets/trivia_science_physics_sat.json'),
  'trivia_science_nature.json': require('./../assets/trivia_science_nature.json'),
  'trivia_science_space.json': require('./../assets/trivia_science_space.json'),
  'trivia_science_technology.json': require('./../assets/trivia_science_technology.json'),
  'trivia_sports_famous_athletes.json': require('./../assets/trivia_sports_famous_athletes.json'),
  'trivia_sports_history.json': require('./../assets/trivia_sports_history.json'),
  'trivia_sports_major_events.json': require('./../assets/trivia_sports_major_events.json'),
  'trivia_sports_rules_and_regulations.json': require('./../assets/trivia_sports_rules_and_regulations.json'),
  'trivia_science_math_sat.json': require('./../assets/trivia_science_math_sat.json'),
  'trivia_science_math.json': require('./../assets/trivia_science_math.json')
};

export default triviaFileMapping;
