import React, { useState, useEffect } from 'react';
import { firestore, auth } from './firebase'; // Import Firestore and Auth instance
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function MyQuizzesViewer() {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  // Check user authentication
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!currentUser) {
      // If user is not logged in, navigate to the login page
      navigate('/');
      return;
    }

    const fetchUserQuizzes = async () => {
      try {
        const userEmail = currentUser.email;
        const quizzesRef = collection(firestore, 'quizzesMetadata');
        const q = query(quizzesRef, where('userEmail', '==', userEmail)); // Filter by userEmail
        const querySnapshot = await getDocs(q);
        
        const quizzesList = querySnapshot.docs.map(doc => doc.data());
        setQuizzes(quizzesList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
        setLoading(false);
      }
    };

    fetchUserQuizzes();
  }, [currentUser, navigate]);

  const handleQuizClick = (quizId) => {
    navigate(`/quizpage?id=${quizId}`);
  };

  const handleNavigateToCreateQuiz = () => {
    navigate('/learning-focus');
  };

  if (loading) {
    return <div>Loading quizzes...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={() => navigate('/landing')} />
        </div>
        <h1 className="text-xl font-bold">My Quizzes</h1>
        <div></div>
      </nav>

      <div className="w-full max-w-3xl mt-8 mb-8 bg-white p-6 rounded-lg shadow-lg text-left">
        <h2 className="text-2xl font-bold mb-6">Your Quizzes</h2>
        {quizzes.length === 0 ? (
          <div className="text-center">
            <p className="text-lg">You haven't commissioned any quizzes yet.</p>
            <button
              className="mt-4 w-full bg-green-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-green-700"
              onClick={handleNavigateToCreateQuiz}
            >
              Create a Quiz
            </button>
          </div>
        ) : (
          <ul className="quiz-list">
            {quizzes.map((quiz, index) => (
              <li 
                key={index} 
                className="quiz-item bg-white p-6 mb-6 rounded-lg shadow-lg cursor-pointer hover:bg-gray-100 transition-all" 
                onClick={() => handleQuizClick(quiz.quizId)} // Handle click to navigate to quiz page
              >
                <h3 className="text-xl font-semibold mb-2">{quiz.quizName.replace(/_/g, ' ')}</h3>
                <p className="mb-2"><strong>Topics:</strong> {quiz.tags.join(', ')}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default MyQuizzesViewer;