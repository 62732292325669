import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDocs, query, where, collection, setDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import bannedWords from './utils/engBadWords.json';  // Import banned words list

const MAX_SCREEN_NAME_LENGTH = 24;  // Set max length for screen name

function CreateScreenName() {
  const [screenName, setScreenName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorToggle, setErrorToggle] = useState(false); // To toggle between messages
  const [isSaving, setIsSaving] = useState(false); // To show saving feedback
  const navigate = useNavigate();
  const location = useLocation();
  const { uid, email } = location.state || {};

  const handleScreenNameChange = (e) => {
    setScreenName(e.target.value);
  };

  const checkScreenNameUniqueness = async () => {
    const q = query(collection(firestore, 'users'), where('screenName', '==', screenName));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty; // Return true if no existing user with this screen name
  };

  const containsBannedWords = (text) => {
    const textLower = text.toLowerCase();
    return bannedWords.some(word => textLower.includes(word));
  };

  const handleSaveScreenName = async () => {
    if (!screenName) {
      setErrorMessage('Please enter a screen name.');
      return;
    }

    if (screenName.length > MAX_SCREEN_NAME_LENGTH) {
      setErrorMessage(`Screen name cannot exceed ${MAX_SCREEN_NAME_LENGTH} characters.`);
      return;
    }

    if (containsBannedWords(screenName)) {
      setErrorMessage('Screen name contains inappropriate words. Please choose another.');
      return;
    }

    setIsSaving(true); // Show saving state
    try {
      const isUnique = await checkScreenNameUniqueness();
      if (!isUnique) {
        // Toggle between two error messages
        setErrorToggle(!errorToggle);
        setErrorMessage(errorToggle ? 'Screen name is already taken. Please try another.' : 'That screen name is taken, please choose another.');
        setIsSaving(false); // Reset the saving state
        return;
      }

      // Save the screen name in Firestore
      await setDoc(doc(firestore, 'users', uid), {
        screenName,
        email,
      }, { merge: true });

      navigate('/landing'); // Redirect to landing page after successful screen name setup

    } catch (error) {
      console.error('Error saving screen name:', error);
      setErrorMessage('Failed to save screen name. Please try again.');
    } finally {
      setIsSaving(false); // Reset the saving state
    }
  };

  return (
    <div className="min-h-screen text-purple-700 flex flex-col items-center" style={{ backgroundColor: '#fff' }}>
      <div className="mt-10 text-center hero-header">
        <h1 className="text-5xl font-bold">Choose your screen name</h1>
        <p className="mt-4 text-lg text-purple-900">This name will be shown on leaderboards and other public areas.</p>
      </div>

      <div className="mt-10 w-full max-w-md p-6 rounded-lg shadow-lg auth-card" style={{ backgroundColor: '#841584' }}>
        <input
          type="text"
          placeholder="Enter your screen name..."
          value={screenName}
          maxLength={MAX_SCREEN_NAME_LENGTH} // Limit input length to 24 characters
          onChange={handleScreenNameChange}
          className="w-full p-3 rounded-lg text-black"
        />

        {errorMessage && (
          <p className="text-xl font-bold mb-2 text-white" style={{ marginTop: '10px', textAlign: 'center' }}>
            {errorMessage}
          </p>
        )}

        <button
          onClick={handleSaveScreenName}
          className={`mt-4 w-full bg-gradient-to-r from-purple-500 to-purple-400 text-white p-3 rounded-lg text-lg font-bold ${isSaving ? 'opacity-50' : ''}`}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save Screen Name'}
        </button>
      </div>
    </div>
  );
}

export default CreateScreenName;
