import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { setDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import stringSimilarity from 'string-similarity';
import quizzesData from './quizdata/quizzes.json';  
import triviaData from './quizdata/trivia.json';   
import triviaFileMapping from './quizdata/TriviaFileMapping'; 
import bannedWords from './utils/engBadWords.json';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faGraduationCap, faMagnifyingGlass, faUser, faArrowRight } from '@fortawesome/free-solid-svg-icons';  // Importing icons
import DailyQuizBanner from './DailyQuizBanner'; 
import useDailyQuiz from './useDailyQuiz'; 
import './LandingPage.css';

function LandingPage() {
  const [learningGoal, setLearningGoal] = useState('');
  const [user, setUser] = useState(null);
  const levels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
  const [screenName, setScreenName] = useState(''); 
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const navigate = useNavigate();

  const { dailyQuiz, streakCount, quizCompleted } = useDailyQuiz(triviaData);  
  const dailyTriviaBannertText = "Today's Daily Quiz";

  // Random Quiz logic: fetch a random quiz
  const getRandomQuiz = () => {
    const randomIndex = Math.floor(Math.random() * triviaData.sections.length);
    const randomSection = quizzesData.sections[randomIndex];
    const randomQuizIndex = Math.floor(Math.random() * randomSection.quizzes.length);
    return { ...randomSection.quizzes[randomQuizIndex], categoryName: randomSection.name };
  };

  const randomQuiz = getRandomQuiz(); // Generate a random quiz

  const handleQuizLaunch = (quiz) => {
    navigate(`/trivia-screen`, {
      state: {
        quizFile: quiz.jsonFile,
        quizName: quiz.name,
        quizType: 'randomQuiz10Timed',  // or whichever type you intend to use
        categoryName: quiz.categoryName || '',
      },
    });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);
        await fetchUserLearningData(currentUser.email);
        await fetchUserScreenName(currentUser.uid); // Fetch screen name
      } else {
        alert('You must be logged in to set a learning goal.');
        navigate('/');
      }
    };

    const fetchUserLearningData = async (email) => {
      try {
        const userDocRef = doc(firestore, 'learningGoals', email);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setLearningGoal(data.learningGoal || '');
        }
      } catch (error) {
        console.error('Error fetching learning goal data:', error);
      }
    };

    const fetchUserScreenName = async (uid) => {
      try {
        const userDocRef = doc(firestore, 'users', uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setScreenName(data.screenName || '');  // Set screen name or fallback
        }
      } catch (error) {
        console.error('Error fetching screen name:', error);
      }
    };

      // Check for the stored learning goal and isSubmitted state in localStorage
    const storedLearningGoal = localStorage.getItem('currentLearningGoal');
    const storedIsSubmitted = localStorage.getItem('isSubmitted') === 'true';  // Retrieve the flag as a boolean

    if (storedLearningGoal) {
      setLearningGoal(storedLearningGoal);
    }
    setIsSubmitted(storedIsSubmitted);  // Set isSubmitted from localStorage

    fetchUserDetails();
  }, [navigate]);


  const containsBannedWords = (text) => {
    const textLower = text.toLowerCase();
    
    // Create a regex pattern that matches the banned words as whole words
    const badWordPattern = new RegExp(`\\b(${bannedWords.join('|')})\\b`, 'gi');
    
    // Return true if there's any match
    return badWordPattern.test(textLower);
  };

  const handleSubmit = async () => {
    if (!learningGoal) {
      alert('Please enter a learning goal.');
      return;
    }

    if (!user) {
      alert('You must be logged in to set a learning goal.');
      return;
    }

    if (containsBannedWords(learningGoal)) {
      alert("Your learning goal contains inappropriate content. Please try again.");
      return;
    }

    try {
      const learningGoalsRef = doc(firestore, 'learningGoals', user.email);
      await setDoc(learningGoalsRef, {
        learningGoal,
        userEmail: user.email,
        userScreenName: user.displayName || user.email,
        timestamp: serverTimestamp(),
      });

      const matchedQuizzes = findMatchingQuizzes(learningGoal);

      console.log("Matched Quizzes:", matchedQuizzes);

      localStorage.setItem('learningFocusQuiz', JSON.stringify({
        learningGoal,
        matchedQuizzes,
      }));

      localStorage.setItem('currentLearningGoal', learningGoal);
      localStorage.setItem('isSubmitted', 'true');  // Persist the submitted state

      setIsSubmitted(true); 
      navigate('/learning-focus', {
        state: { learningGoal, matchedQuizzes }
      });

    } catch (error) {
      console.error('Error saving learning goal:', error);
      alert('Failed to save your learning goal. Please try again later.');
    }
  };

  const handleTriviaExplorer = async () => {
    navigate('/trivia'); 
  }
  const handleQuizExplorer = async () => {
    navigate('/quizzes-free'); 
  }
  const handleQuizSearch = async () => {
    navigate('/quizzes-viewer'); 
  }
  const handleMyQuizzes = async () => {
    navigate('/my-quizzes-viewer'); 
  }

  const findMatchingQuizzes = (goal, threshold = 0.42, maxResults = 5) => {
    const allQuizzes = [...quizzesData.sections, ...triviaData.sections];
    const matchingQuizzes = [];

    for (const section of allQuizzes) {
      for (const quiz of section.quizzes) {
        const quizData = triviaFileMapping[quiz.jsonFile];
        
        if (!quizData) {
          continue;
        }

        const quizName = quiz.name.toLowerCase();
        const goalLower = goal.toLowerCase();

        const nameSimilarity = stringSimilarity.compareTwoStrings(quizName, goalLower);
        const metadataSimilarity = quizData.metadata?.quizName 
          ? stringSimilarity.compareTwoStrings(quizData.metadata.quizName.toLowerCase(), goalLower)
          : 0;
        const tagsSimilarity = quizData.questions?.reduce((max, question) => {
          const questionMax = question.tags?.reduce((tagMax, tag) => {
            const tagSimilarity = stringSimilarity.compareTwoStrings(tag.toLowerCase(), goalLower);
            return Math.max(tagMax, tagSimilarity);
          }, 0) || 0;
          return Math.max(max, questionMax);
        }, 0) || 0;

        const bestMatch = Math.max(nameSimilarity, metadataSimilarity, tagsSimilarity);

        if (bestMatch >= threshold) {
          matchingQuizzes.push({
            ...quiz,
            matchScore: bestMatch
          });
        }
      }
    }

    matchingQuizzes.sort((a, b) => b.matchScore - a.matchScore);
    return matchingQuizzes.slice(0, maxResults);
  };

  const handleBannerClick = () => {
    navigate('/learning-focus', {
      state: { learningGoal }
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
            <FontAwesomeIcon icon={faUser} className="text-white text-2xl" onClick={() => navigate('/profile')} />
            {screenName && (
                <span className="ml-2 text-lg cursor-pointer" onClick={() => navigate('/profile')}>
                {screenName}
                </span>
            )}
        </div>

        <h1 className="text-center text-xl font-bold">Quiznect</h1>
        <div></div> {/* Empty div to balance the profile icon on the left */}
      </nav>

      {/* Conditionally render the banner or the input section */}
      {isSubmitted && learningGoal ? (
        <div
          className="bg-purple-600 text-white rounded-lg p-6 flex justify-between items-center w-full max-w-2xl my-10" 
          onClick={handleBannerClick}
          style={{ backgroundColor: '#841584', cursor: 'pointer' }}
        >
          <div className="flex items-center">
            <FontAwesomeIcon icon={faLightbulb} className="text-white text-4xl mr-4" />
            <div>
              <h2 className="text-xl font-bold">Learning Focus: {learningGoal}</h2>
              <p className="text-sm">Continue your learning plan based on your goal.</p>
            </div>
          </div>
          <FontAwesomeIcon icon={faArrowRight} className="text-white text-4xl" />
        </div>
      ) : (
        <div className="section-container mt-10 text-center">
          <h1 className="text-2xl font-bold">What would you like to learn?</h1>
          <p className="mt-4 text-base">Tell us your learning goal, and we'll create a custom quiz plan just for you.</p>
          <input
            type="text"
            placeholder="I want to learn..."
            value={learningGoal}
            onChange={(e) => setLearningGoal(e.target.value)}
            className="w-full p-2 mt-4 rounded-lg border border-gray-300 text-black"
          />
          <button
            onClick={handleSubmit}
            style={{ backgroundColor: '#841584' }}
            className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold"
          >
            Submit
          </button>
        </div>
      )}

  
      {/* Section: Search & Free trivia and quizzes */}
      <div className="section-container mt-6 text-center">
        <p className="text-2xl font-bold">Explore Quizzes and Trivia</p>
        <button
          onClick={handleMyQuizzes}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faUser} className="mr-2" /> My Quizzes
        </button>
        <button
          onClick={handleQuizSearch}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" /> Quiz search
        </button>
        <button
          onClick={handleTriviaExplorer}
          style={{ backgroundColor: '#841584' }} 
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faLightbulb} className="mr-2" /> Free Trivia
        </button>
        <button
          onClick={handleQuizExplorer}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faGraduationCap} className="mr-2" /> Free Quizzes
        </button>
      </div>

        {/* Daily Quiz Banner */}
        <DailyQuizBanner style={{ marginTop: -30 }}
        bannerText={dailyTriviaBannertText}
        quiz={dailyQuiz}
        streakCount={streakCount}
        quizCompleted={quizCompleted}
        onQuizStart={() => handleQuizLaunch(dailyQuiz)}
        />

    </div>
  );
}

export default LandingPage;




// todo: {/* Plan Section */}
      /*
      <div className="mt-16 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Choose Your Plan</h2>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-4">Monthly Subscription</h3>
          <p className="text-gray-700 mb-4">Get a new quiz generated every month to help you master your chosen topic.</p>
          <p className="text-2xl font-bold">$9.99/month</p>
          <button
            className="mt-4 w-full bg-green-600 text-white p-3 rounded-lg text-base font-bold"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    */