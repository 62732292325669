// quizUtils.js

// FNV-1a hash function for generating a random quiz based on the date
export const fnv1aHash = (str) => {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
      hash ^= str.charCodeAt(i);
      hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
    }
    return hash >>> 0;
  };
  
  // Function to fetch the daily quiz
  export const fetchDailyQuiz = (quizzesData, setDailyQuiz) => {
    const today = new Date().toISOString().slice(0, 10); // Get YYYY-MM-DD format
    const hash = fnv1aHash(today);
    const allQuizzes = quizzesData.sections.flatMap(section =>
      section.quizzes.map(quiz => ({ ...quiz, categoryName: section.name }))
    );
    const index = hash % allQuizzes.length;
    const todayQuiz = allQuizzes[index];
  
    if (todayQuiz) {
      setDailyQuiz(todayQuiz);
    } else {
      console.error("No quiz found for today.");
    }
  };
  