import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAuxi7PShk24rF9L5aqvZxJXTA_cMkZsVU",
  authDomain: "quiznect.firebaseapp.com",
  projectId: "quiznect",
  storageBucket: "quiznect.appspot.com",
  messagingSenderId: "473044494109",
  appId: "1:473044494109:web:c166d5611f5d15079963f4",
  measurementId: "G-QZS29G0CFR"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
