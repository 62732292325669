import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function QuizPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to desanitize the quiz name
  const desanitizeQuizName = (quizName) => {
    return quizName.replace(/_/g, ' '); // Replaces underscores with spaces
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const quizId = queryParams.get('id');

    const fetchQuizData = async () => {
      if (quizId) {
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
        const quizDocSnap = await getDoc(quizDocRef);

        if (quizDocSnap.exists()) {
          setQuizData(quizDocSnap.data());
        } else {
          console.log('No such document!');
        }

        setLoading(false);
      }
    };

    fetchQuizData();
  }, [location]);

  // Function to handle quiz launch
  const handleQuizLaunch = () => {
    if (quizData) {
      navigate(`/play-quiz`, {
        state: {
          quizFile: quizData.storagePath,  // This will be used to fetch quiz from GCP
          quizName: desanitizeQuizName(quizData.quizName),
          quizType: 'randomQuiz10Timed',
          categoryName: quizData.tags.join(', ') || '',  // Use tags or any category name
        },
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!quizData) {
    return <div>Quiz not found!</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={() => navigate('/landing')} />
        </div>
        <h1 className="text-xl font-bold">Quiznect.com</h1>
        <div></div>
      </nav>

      {/* Quiz Name */}
      <div className="relative mb-12 mt-8">
        <h2 className="text-4xl font-bold text-gray-900 bg-gray-200 p-6 rounded-lg shadow-lg">
          {desanitizeQuizName(quizData.quizName)}
        </h2>
      </div>

      {/* Play Random Quiz Button */}
      <button
        onClick={handleQuizLaunch}
        className="mb-8 w-full max-w-md bg-blue-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-blue-700"
      >
        Play Quiz
      </button>

      {/* Quiz Metadata */}
      <div className="w-full max-w-3xl mb-8 bg-white p-6 rounded-lg shadow-lg text-left">
        <h3 className="text-2xl font-semibold mb-4">Quiz Details</h3>
        <p><strong>Topics:</strong> {quizData.tags.join(', ')}</p>

        {/* You can add more quiz-related content here */}
      </div>
    </div>
  );
}

export default QuizPage;

/*
<p><strong>Created:</strong> {quizData.createdAt}</p>

 <p><strong>Popularity:</strong> {quizData.popularity}</p>
        <p><strong>Public:</strong> {quizData.isPublic ? 'Yes' : 'No'}</p>
        */