import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { OAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { firestore } from './firebase'; 
import { FaGoogle, FaApple } from 'react-icons/fa'; // Import Google and Apple icons
import './HomePage.css';

function HomePage() {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLogin, setIsLogin] = useState(true); // Toggle between login and signup
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');
    
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/landing'); // Navigate to LandingPage after successful login/signup
    }
  }, [user, navigate]);

  // Listen for Enter key press and trigger login/signup
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleAuthAction(); // Call the login or signup function when Enter is pressed
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [email, password, isLogin]);


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  
  const handleAuthAction = async () => {
    try {
      let userCredential;
  
      if (isLogin) {
        // Log in the user
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        setUser(userCredential.user);
        console.log('Login successful:', userCredential.user);
  
        // Check if the user has a screen name
        const userDoc = await getDoc(doc(firestore, 'users', userCredential.user.uid));
        if (userDoc.exists() && userDoc.data().screenName) {
          navigate('/landing'); // User has a screen name, navigate to landing page
        } else {
          navigate('/create-screen-name', { state: { uid: userCredential.user.uid, email } }); // No screen name, redirect to setup
        }
        
      } else {
        // Sign up the user without adding the screen name
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        setUser(userCredential.user);
        console.log('Signup successful:', userCredential.user);
  
        // After successful signup, redirect to screen name setup
        navigate('/create-screen-name', { state: { uid: userCredential.user.uid, email } });
      }
  
      // Handle Remember Me functionality
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }
  
    } catch (error) {
      console.error('Error during authentication:', error);
      alert(error.message); 
    }
  };
  

  const toggleAuthMode = () => {
    setIsLogin((prev) => !prev);
  };

  const signInWithApple = async () => {
    const provider = new OAuthProvider('apple.com');
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log('Apple login successful:', result.user);
    } catch (error) {
      console.error('Error during Apple login:', error);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new OAuthProvider('google.com');
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log('Google login successful:', result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  return (
    <div className="min-h-screen text-purple-700 flex flex-col items-center" style={{ backgroundColor: '#fff' }}>
      {/* Hero Section */}
      <div className="mt-10 text-center hero-header">
        <h1 className="text-5xl font-bold" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)' }}>
          Create custom tailored Quizzes to learn anything.
        </h1>
        <p className="mt-4 text-lg text-purple-900">
          Get personalized learning guides and quiz materials on any topic, powered by AI. Start now!
        </p>
      </div>
  
      {/* Call to Action and Authentication */}
      <div className="mt-10 text-white p-6 rounded-lg shadow-lg w-full max-w-md auth-card" style={{ backgroundColor: '#841584' }} >
        <input
          id="email-input"
          type="email"
          placeholder="Type your email..."
          value={email}
          onChange={handleEmailChange}
          className="w-full p-3 rounded-lg text-black input-field"
        />
        <input
          type="password"
          placeholder="Type your password..."
          value={password}
          onChange={handlePasswordChange}
          className="w-full p-3 mt-4 rounded-lg text-black input-field"
        />

        {/* Remember Me Checkbox */}
        <div className="flex items-center mt-4">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMeChange}
            className="mr-2"
          />
          <label className="text-white">Remember Me</label>
        </div>

        <button
          onClick={handleAuthAction}
          className="mt-4 w-full bg-gradient-to-r from-purple-500 to-purple-400 text-white p-3 rounded-lg text-lg font-bold button"
        >
          {isLogin ? 'Log In' : 'Sign Up'}
        </button>
        
        <button
          onClick={toggleAuthMode}
          className="mt-2 w-full bg-gray-700 text-white p-3 rounded-lg"
        >
          {isLogin ? 'Create an Account' : 'Already have an account? Log In'}
        </button>

        <div className="text-center mt-4 text-gray-200 or-divider">or</div>
        
        {/* Google Login */}
        <button
          onClick={signInWithGoogle}
          className="mt-2 w-full bg-white text-black p-3 rounded-lg flex justify-center items-center auth-buttons"
        >
          <FaGoogle className="w-6 h-6 mr-2" />
          Continue with Google
        </button>
        
        {/* Apple Login */}
        <button
          onClick={signInWithApple}
          className="mt-2 w-full bg-white text-black p-3 rounded-lg flex justify-center items-center auth-buttons"
        >
          <FaApple className="w-6 h-6 mr-2" />
          Continue with Apple
        </button>

        <p className="text-center mt-4 text-sm text-gray-200">
          If you already have an account, we'll log you in.
        </p>
      </div>

      {/* Example Gallery */}
      <div className="mt-16 w-full max-w-4xl">
        <h2 className="text-2xl font-bold mb-8 text-center">Explore What You Can Learn</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="gallery-card p-6 rounded-lg shadow-lg" style={{ backgroundColor: '#841584' }}>
            <h3 className="text-xl font-bold mb-2 text-white">“I want to learn Python programming.”</h3>
            <p className="text-white">Get quizzes and resources to master Python from basics to advanced concepts.</p>
          </div>
          <div className="gallery-card p-6 rounded-lg shadow-lg" style={{ backgroundColor: '#841584' }}>
            <h3 className="text-xl font-bold mb-2 text-white">“I want to improve my public speaking skills.”</h3>
            <p className="text-white">Receive tailored exercises and quizzes to boost your public speaking confidence.</p>
          </div>
          <div className="gallery-card p-6 rounded-lg shadow-lg" style={{ backgroundColor: '#841584' }}>
            <h3 className="text-xl font-bold mb-2 text-white">“I want to master data analysis with Excel.”</h3>
            <p className="text-white">Learn how to perform complex data analysis with interactive quizzes and examples.</p>
          </div>
          <div className="gallery-card p-6 rounded-lg shadow-lg" style={{ backgroundColor: '#841584' }}>
            <h3 className="text-xl font-bold mb-2 text-white">“I want to create effective marketing campaigns.”</h3>
            <p className="text-white">Discover strategies and quizzes to develop successful marketing campaigns.</p>
          </div>
          <div className="gallery-card p-6 rounded-lg shadow-lg" style={{ backgroundColor: '#841584' }}>
            <h3 className="text-xl font-bold mb-2 text-white">“I want to prepare for a job interview in tech.”</h3>
            <p className="text-white">Get quizzes and interview prep materials tailored to the tech industry.</p>
          </div>
          <div className="gallery-card p-6 rounded-lg shadow-lg" style={{ backgroundColor: '#841584' }}>
            <h3 className="text-xl font-bold mb-2 text-white">“I want to learn wilderness survival.”</h3>
            <p className="text-white">Take quizzes to test if you know what it takes to survive in extreme conditions.</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default HomePage;
