import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { doc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function QuizGenerationProgressPage() {
  const [progress, setProgress] = useState({
    status: 'Starting...',
    percentage: 0,
  });

  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Initialize Firebase Functions
  const functions = getFunctions(undefined, 'us-central1');
  const publishQuizGenerationRequest = httpsCallable(functions, 'publishQuizGenerationRequest');

  // Set up an observer on Firebase Auth to ensure it's initialized
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      setIsAuthInitialized(true);
    });
    return () => unsubscribe();
  }, []);

  // Effect to verify payment when the component mounts
  useEffect(() => {
    if (!isAuthInitialized) return;

    const verifyPaymentAndInitiateQuizGeneration = async () => {
      const sessionId = new URLSearchParams(location.search).get('session_id');
      if (!sessionId) {
        navigate('/learning-focus'); // Redirect if no session ID
        return;
      }

      try {
        const response = await fetch(
          `https://us-central1-quiznect.cloudfunctions.net/retrieveSession?sessionId=${sessionId}`
        );

        if (!response.ok) {
          navigate('/learning-focus'); // Redirect on error
          return;
        }

        const session = await response.json();

        if (session.payment_status === 'paid') {
          const currentUser = auth.currentUser;
          if (!currentUser) {
            navigate('/learning-focus');
            return;
          }
          const userEmail = currentUser.email;
          const userDocRef = doc(firestore, 'learningGoals', userEmail);

          await updateDoc(userDocRef, {
            quizGenerationStatus: 'pending',
            progressPercentage: 0, // Initialize progress
          });

          const docSnap = await getDoc(userDocRef);
          let learningGoal = 'No learning goal provided';
          if (docSnap.exists()) {
            const data = docSnap.data();
            learningGoal = data.learningGoal || learningGoal;
          }

          try {
            await publishQuizGenerationRequest({
              userEmail,
              learningGoal,
            });
          } catch (error) {
            console.error('Error publishing quiz generation request:', error);
          }
        } else {
          navigate('/learning-focus'); // Redirect if payment not verified
        }
      } catch (error) {
        navigate('/learning-focus');
      }
    };

    verifyPaymentAndInitiateQuizGeneration();
  }, [isAuthInitialized, location, navigate, publishQuizGenerationRequest]);

  // Effect to listen to Firestore updates for quiz generation progress
  useEffect(() => {
    if (!isAuthInitialized) return;

    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const userEmail = currentUser.email;
    const userDocRef = doc(firestore, 'learningGoals', userEmail);

    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      const data = docSnap.data();
      setProgress({
        status: data.quizGenerationStatus || 'Starting...',
        percentage: data.progressPercentage || 0,
      });

      if (data.quizGenerationStatus === 'Completed') {
        navigate('/your-quizzes');  // Adjust the redirect path as needed
      }
    });

    return () => unsubscribe();
  }, [isAuthInitialized, navigate]);

  const handleGoHome = () => {
    navigate('/landing');
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={handleGoHome} />
        </div>
        <h1 className="text-xl font-bold">Quiz Generation Progress</h1>
        <div></div>
      </nav>

      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg mt-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Generating Quiz...</h2>

        <div className="w-full bg-gray-200 rounded-full h-6 mb-6">
          <div
            className="bg-green-600 h-6 rounded-full transition-all duration-500"
            style={{ width: `${progress.percentage}%` }}
          ></div>
        </div>

        <p className="text-center text-gray-700">{progress.status}</p>
      </div>
    </div>
  );
}

export default QuizGenerationProgressPage;