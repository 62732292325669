// DailyQuizBanner.js
import React from 'react';
import './DailyQuizBanner.css';  // Create a separate CSS for the banner if needed

const DailyQuizBanner = ({ bannerText, quiz, streakCount, quizCompleted, onQuizStart }) => {
  if (!quiz) return null;

  return (
    <div className="daily-quiz-banner">
      <p>Category: {quiz.categoryName}</p>
      <h2>{bannerText}: {quiz.name}</h2>
      <button
        className={`start-quiz-button ${quizCompleted ? 'completed' : ''}`}
        onClick={onQuizStart}
        disabled={quizCompleted}
        style={{ backgroundColor: '#841584', cursor: 'pointer' }}
      >
        {quizCompleted ? 'Quiz Completed' : 'Start Daily Quiz'}
      </button>
      <p>Daily Streak: {streakCount} days</p>
    </div>
  );
};

export default DailyQuizBanner;
