
//for PROD: 
//const stripePromise = loadStripe('pk_live_51IAbUnFEfwu9Vl237qwXEjSFm7y4O0iKsZWWamNZOVpye5XjCoaQyGOblWRZh20CW7rpJ60akG4Pvp6VsC8cmaSF00wKvFDvEr');

// LearningFocusPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { FaPen } from 'react-icons/fa'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons'; 
import { loadStripe } from '@stripe/stripe-js';


function LearningFocusPage() {
  const [learningGoal, setLearningGoal] = useState('Loading...');
  const [evaluationScore, setEvaluationScore] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [learningPlan, setLearningPlan] = useState([]);
  const [enhancedLearningPlan, setEnhancedLearningPlan] = useState([]);  // New state for enhanced learning plan
  const [matchedQuizzes, setMatchedQuizzes] = useState([]);
  const [pollingIntervalId, setPollingIntervalId] = useState(null);
  const [quizGenerationStatus, setQuizGenerationStatus] = useState(null); 
  const [quizStoragePath, setQuizStoragePath] = useState(''); 
  const navigate = useNavigate();
  const location = useLocation();
  const stripePromise = loadStripe('pk_test_51Pyd26FMJLQLKujTqyFrcihstqly9qFhJrsgTKSw5wriMnaZMNEh01sncXdl1houdHEvRvkc3CYsJCr3Lc95OE1Y00KXaJXZ4z');
  
  const handleGenerateQuiz = async () => {
    const stripe = await stripePromise;
    const currentUser = auth.currentUser;
    const userEmail = currentUser.email;
  
    const response = await fetch('https://us-central1-quiznect.cloudfunctions.net/createCheckoutSession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userEmail }),
    });
  
    const { id } = await response.json();
  
    const { error } = await stripe.redirectToCheckout({ sessionId: id });
    if (error) {
      console.error('Stripe checkout error:', error);
    }
  };

  const parseLearningPlan = (planString) => {
    console.log("planString" + planString);

    if (!planString || typeof planString !== 'string') return [];
    const milestones = planString.split('Milestone:').slice(1);

    return milestones.map((milestone) => {
      const [milestoneTitle, topics] = milestone.split('Topics:');
      return {
        milestone: milestoneTitle.trim(),
        topics: topics ? topics.trim().split(', ') : []
      };
    });
  };

  // Store learning goal in localStorage and check for changes
  const handleLearningGoalChange = async (newLearningGoal) => {
    const storedGoal = localStorage.getItem('learningGoal');
    if (storedGoal !== newLearningGoal) {
      console.log("calling fetchEnhancedLearningOverview on newLearningGoal: " + newLearningGoal );
      localStorage.setItem('learningGoal', newLearningGoal);
      // No need to call any function here for fetching the enhanced plan separately
    }
  };

  useEffect(() => {
    const fetchLearningData = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        navigate('/'); 
        return;
      }

      const userEmail = currentUser.email;
      const userDocRef = doc(firestore, 'learningGoals', userEmail);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const currentLearningGoal = data.learningGoal || 'No Learning Goal';
        console.log("docSnap exists - currentLearningGoal: " + currentLearningGoal);
        setLearningGoal(currentLearningGoal);
        setEvaluationScore(data.evaluationScore);
        setFeedback(data.feedback);
        
        // Check if quiz generation is completed or in progress
        if (data.quizGenerationStatus) {
            setQuizGenerationStatus(data.quizGenerationStatus); // Set the status from Firestore
        }

        if (data.outputFilePath) {
            setQuizStoragePath(data.outputFilePath);  // Set the storage path for the completed quiz
        }

        // Parsing learning plan string
        const parsedLearningPlan = parseLearningPlan(data.learningPlan || '');
        setLearningPlan(parsedLearningPlan);

        // Set enhanced learning plan if exists
        if (data.enhancedLearningPlan) {
          setEnhancedLearningPlan(data.enhancedLearningPlan);
        }

        if (location.state && location.state.matchedQuizzes) {
          setMatchedQuizzes(location.state.matchedQuizzes);
        } else if (data.matchedQuizzes) {
          setMatchedQuizzes(data.matchedQuizzes);
        }

        await handleLearningGoalChange(currentLearningGoal);
      }
    };

    fetchLearningData();
  }, [navigate, location]);

  useEffect(() => {
    const startPolling = () => {
      if (pollingIntervalId) return; 

      const intervalId = setInterval(async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userEmail = currentUser.email;
        const userDocRef = doc(firestore, 'learningGoals', userEmail);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          setLearningPlan(parseLearningPlan(docSnap.data().learningPlan || ''));

          // Update enhanced learning plan if available
          if (docSnap.data().enhancedLearningPlan) {
            setEnhancedLearningPlan(docSnap.data().enhancedLearningPlan);
          }

          clearInterval(pollingIntervalId);
        }
      }, 5000); 

      setPollingIntervalId(intervalId);
    };

    startPolling();
    return () => clearInterval(pollingIntervalId);
  }, [pollingIntervalId]);

  const handleQuizLaunch = (quiz) => {
    navigate(`/trivia-screen`, {
      state: {
        quizFile: quiz.jsonFile,
        quizName: quiz.name,
        quizType: 'randomQuiz10Timed',
        categoryName: quiz.categoryName || '',
      },
    });
  };

  const showQuizPage = () => {
    // You need to pass the quizId, which could be derived from the Firestore data
    const quizId = "20240917223038004747"; // Replace this with dynamic data if needed
    navigate(`/quizpage?id=${quizId}`);
  };

  const handleEditLearningGoal = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const userEmail = currentUser.email;
    const learningGoalsRef = doc(firestore, 'learningGoals', userEmail);
  
    try {
      localStorage.removeItem('isSubmitted');
      localStorage.setItem('currentLearningGoal', learningGoal);
  
      await setDoc(learningGoalsRef, {
        learningGoal: '',
        learningPlan: '',
        timestamp: serverTimestamp(),
      });
  
      navigate('/landing');
    } catch (error) {
      console.error('Error resetting learning goal:', error);
      alert('Failed to reset your learning goal. Please try again.');
    }
  };

  const renderLearningPlan = () => {
    if (!Array.isArray(learningPlan) || learningPlan.length === 0) {
        return <p>Creating a learning plan...</p>;
    }

    return learningPlan.map((milestone, index) => {
        // Find the matching enhanced milestone by comparing milestone titles
        const enhancedInfo = enhancedLearningPlan.find(enhancedMilestone => enhancedMilestone.milestone === milestone.milestone);
    
        return (
            <div key={index} className="mb-6 p-4 border-l-4 border-purple-500 bg-gray-50 rounded-lg shadow-sm text-left">
            <h3 className="text-lg font-bold">{milestone.milestone}</h3>
            
            {milestone.topics && milestone.topics.length > 0 && (
                <ul className="mt-2 text-sm text-gray-700 list-disc pl-5">
                {milestone.topics.map((topic, topicIndex) => (
                    <li key={topicIndex}>{topic}</li>
                ))}
                </ul>
            )}
    
            {/* Insert enhanced explanation and URL if it exists */}
            {enhancedInfo && (
                <>
                {enhancedInfo.explanation && (
                    <p className="mt-2 text-sm text-gray-700">
                    {enhancedInfo.explanation}
                    </p>
                )}
    
                {enhancedInfo.url && (
                    <a href={enhancedInfo.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                    {enhancedInfo.url.replace(/^https?:\/\//, '')}
                    </a>
                )}
                </>
            )}
            </div>
        );
      });
    };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={() => navigate('/landing')} />
        </div>
        <h1 className="text-xl font-bold">Your Learning Focus</h1>
        <div></div>
      </nav>
      
      <div className="relative mb-12 " style={{ marginTop: '10px' }} >
        <p
          className="text-4xl font-bold text-gray-900 bg-gray-200 p-6 rounded-lg shadow-lg cursor-pointer flex items-center"
          onClick={handleEditLearningGoal}
        >
          {learningGoal}
          <span className="mx-4 h-6 border-l-2 border-gray-300"></span>
          <FaPen className="text-gray-600 cursor-pointer text-sm ml-2" onClick={handleEditLearningGoal} />
        </p>
      </div>

      {feedback && typeof evaluationScore === 'number' && evaluationScore >= 0.65 && (
        <div className="p-4 bg-gray-100 rounded-lg border border-gray-300 mb-8">
          <p className="text-sm text-gray-600">{feedback}</p>
        </div>
      )}

       {/* Conditional banner based on quiz generation status */}
       <div className="w-full max-w-md mb-8">
        {quizGenerationStatus === 'Completed' ? (
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-center">Your custom quiz is ready!</h3>
            <button
              onClick={showQuizPage}
              className="mt-4 w-full bg-blue-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-blue-700"
            >
              Take Your Quiz
            </button>
          </div>
        ) : quizGenerationStatus ? (
          <div className="bg-yellow-100 p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-center">Quiz generation in progress...</h3>
            <p className="text-gray-700 text-center">Current status: {quizGenerationStatus}</p>
          </div>
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-center">Generate customized quizzes for:</h3>
            <p className="text-lg font-bold mb-2 text-purple-700 text-center">{learningGoal}</p>
            <p className="text-gray-700 mb-4 text-center">Get 100s of quizzes generated for your learning focus in under 24 hours!</p>
            <p className="text-4xl font-bold text-green-700 mt-2 text-center">$9.99</p>
            <button
              onClick={handleGenerateQuiz}
              className="mt-4 w-full bg-green-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-green-700"
            >
              Generate Quiz
            </button>
          </div>
        )}
      </div>


      {/* Standard Learning Plan */}
      <div className="w-full max-w-3xl mb-8">
        {renderLearningPlan()}
      </div>

      {/* Related quizzes section */}
      {matchedQuizzes.length > 0 && (
        <div className="bg-gray-50 p-6 rounded-lg border border-gray-300 w-full max-w-md mt-8">
          <p className="text-gray-700 mb-4">Free related quizzes:</p>
          {matchedQuizzes.map((quiz, index) => (
            <button
              key={index}
              className="bg-[#841584] text-white p-3 rounded-lg w-full mb-4 transition-all hover:bg-[#5f0f63] transform hover:scale-105"
              onClick={() => handleQuizLaunch(quiz)}
            >
              {quiz.name}
            </button>
          ))}
        </div>
      )}

    </div>
  );
}

export default LearningFocusPage;
